body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.modal {
    display: block;
    visibility: hidden;
    background-color: rgba(0, 0, 0, 0);
    transition: all 0.15s linear;
}

.modal.show {
    visibility: visible;
    display: block;
    background-color: rgba(0, 0, 0, 0.5);
}
