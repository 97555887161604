.login {
    padding: 4px;
}

.img-fluid {
    width: 231px;
    height: 100px;
    object-fit: cover;
}

.login .form * {
    margin-right: 4px;
}

.alert {
    margin-top: 1rem;
}

img {
    width: 50%
}

/* remove "x" for invalid fields */
.form-control.is-invalid {
    background-image: none !important;
}
